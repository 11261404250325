import { Suspense, lazy} from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';


const Loader = (Component) => (props) =>{
   
  return (
    <Suspense fallback={<SuspenseLoader/>}>
      <Component {...props}/>
    </Suspense>
  );
}
// Pages

// const Overview = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
// const Clase  = Loader(lazy(() => import('src/content/client/classes')));
// const AboutUS = Loader(lazy(() => import('src/content/client/about')));
// const Plan = Loader(lazy(() => import('src/content/client/plan')));
// const Profile = Loader(lazy(() => import('src/content/client/profile')));
// const Buy = Loader(lazy(() => import('src/content/client/buy')));
const Shop = Loader(lazy(() => import('src/content/client/shop')));
const Verify = Loader(lazy(() => import('src/content/client/verify')));
const Recovery= Loader(lazy(() => import('src/content/client/recovery')));
// const Reserve = Loader(lazy(() => import('src/content/client/reserve')));

const baseRoutes = [
  // {
  //   path: '/',
  //   element: <Overview />
  // },
  // {
  //   path:'classes',
  //   element:<Clase/>
  // },
  // {
  //   path:'about_us',
  //   element:<AboutUS/>
  // },
  // {
  //   path:'plans',
  //   element:<Plan/>
  // },
  // {
  //   path:'profile',
  //   element:<Profile/>
  // },
  //  {
  //   path:'profile/:id',
  //   element:<Profile/>
  // },
  // {
  //   path:'buy',
  //   element:<Buy/>
  // },
  // {
  //   path:'buy/:id',
  //   element:<Buy/>
  // },
  {
    path:'shop',
    element:<Shop/>
  },
  {
    path:'shop/:id',
    element:<Shop/>
  },
  {
    path:'verify/:id',
    element:<Verify/>
  },
  {
    path:'verify',
    element:<Verify/>
  },
  {
    path:'recovery-password/:id',
    element:<Recovery/>
  },
  {
    path:'recovery-password',
    element:<Recovery/>
  },
  // {
  //   path:'reserve/:id',
  //   element:<Reserve/>
  // },
  // {
  //   path:'reserve',
  //   element:<Reserve/>
  // },
  // {
  //   path: 'overview',
  //   element: <Navigate to="/" replace />
  // },
  {
    path: 'status',
    children: [
      {
        path: '/',
        element: <Navigate to="404" replace />
      },
      {
        path: '',
        element: <Status404 />
      },
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default baseRoutes;
